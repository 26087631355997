nav, main, footer{
    width: 100%;
}

.border-main{
    border-color: #EDAE49;
}

.authform .bg-main{
    background: #EDAE49;
}

.authform{
    background-color: transparent;
    border: 1px solid #29292D;
}

.authform .fields label{
    font-weight: 400;
    font-size: 11px;
    letter-spacing: 0.5px;
    line-height: 18px;
    color: #FFFFFF;
    text-transform: uppercase;
}

.authform input{
    border-width: 1px 1px 3px 1px;
    border-color: #29292D;
    width: 100%;
    padding: .75rem 0rem;
    background: transparent;
    color: #FFFFFF;
}

.authform .btn-main{
    font-weight: 600;
    padding: 1rem;
    font-size: 16px;
}

.authform .radios {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.authform .radios .radio {
    width: 50%;
}

.authform .radio input {
    pointer-events: none;
    visibility: hidden;
}

.authform .radio input:focus+label {
    background: transparent;
}

.authform .radio input:focus+label .checker {
    border-color: #EDAE49;
}

.authform .radio input:checked+label .checker {
    box-shadow: inset 0 0 0 4px #FFF;
}

.authform .radio input:checked+label {
    /* background: #EDAE49; */
    border-bottom: 1px solid #EDAE49;
    color: white;
}

.authform .radio label {
    border-bottom: 1px solid transparent;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 28px;
    /* border-radius: 30px; */
    margin-right: 10px;
    font-size: 16px;
    margin-top: -20px !important;
    margin-bottom: 0px !important;
    color: #FFF;
    /*        padding: 0 15px 0 8px;*/
    padding: 18px 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: transparent;
}

.authform .radio label:hover {
    background: #EDAE4930;
}

.authform .radio label:hover .checker {
    box-shadow: inset 0 0 0 2px #EDAE49;
}

.authform .radio .checker {
    display: none;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    margin-right: 8px;
    box-shadow: inset 0 0 0 2px #ccc;
    transition: box-shadow 0.3s ease;
}

.authform .radio label.active {
    background: #EDAE49;
    color: #000;
}

.authform .radio label.active .checker {
    background: #EDAE49;
    color: #000;
    box-shadow: inset 0 0 0 2px #FFF;
}

@media only screen and (max-width: 768px) {
    .authform{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        border: none;
        padding: 0px !important;
    }
  }