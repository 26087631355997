div[class^='react-scroll-to-bottom']::-webkit-scrollbar {
    width: 6px !important;
}

/* Track */
div[class^='react-scroll-to-bottom']::-webkit-scrollbar-track {
    background: #000 !important;
}

/* Handle */
div[class^='react-scroll-to-bottom']::-webkit-scrollbar-thumb {
    background: #333 !important;
}

/* Handle on hover */
div[class^='react-scroll-to-bottom']::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
}

.active-user{
    /* background-color: ; */
}


@media only screen and (max-width: 768px) {
    .right-box{
        min-height: calc(100vh - 50px) !important;
        height: calc(100vh - 50px) !important;
    }
}
