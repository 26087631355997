.order{
    background: black;
    color: white;
    border-color: rgb(107 114 128 / 1);
    border-width: 1px;
    border-radius: 0.375rem;
}

.order h6{
    color: rgb(163 163 163 / 1);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1rem;
    margin-bottom: 7px;
}

.order p{
    font-size: 0.9rem;
    line-height: 1rem;
    font-weight: 500;
    letter-spacing: .15px;
}

.order .btn-refund{
    display: inline-block;
    margin-right: 15px;
    padding: 0.75rem 1.25rem 0.75rem 1.25rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.16px;
    background: #F63E02;
    color: #000000;
    transition: .25s;
}

.order .btn-open{
    display: inline-block;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.16px;
    background: #EDAE49;
    color: #000000;
    transition: .25s;
}

.order .btn-redeemed{
    display: inline-block;
    margin-right: 15px;
    padding: 0.75rem 2.1rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.16px;
    background: rgb(134 239 172);
    color: #000;
    transition: .25s;
}

.order .btn-transferred{
    display: inline-block;
    margin-right: 15px;
    padding: 0.75rem 1.8rem;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.16px;
    background: rgb(134 239 172);
    color: #000000;
    transition: .25s;
}

.codeinput{
    width: 4rem!important;
    height: 4rem;
    margin: 0 .75rem;
    font-size: 2.5rem;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid rgba(0,0,0,.3);
    text-transform: uppercase;
}

@media only screen and (max-width: 768px) {
  .codeinput{
    width: 2.5rem!important;
    height: 2.5rem;
    font-size: 1.5rem;
    margin: 0 .5rem;
    color: black;
  }
}