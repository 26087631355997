main.settings form{
}

main.settings form label{
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.5px;
    color: #FFF;
}

main.settings form input{
    width: 100%;
    padding: .75rem 1rem;
    background: #262626;
    color: #FFFFFF;
    box-shadow: inset 0px -1px 0px #6F6F6F;
    margin-top: 5px;
}

main.settings form .btn-main, main.settings form .btn-main-inverse{
    font-weight: 600;
    padding: 1.15rem;
    border: 1px solid var(--main-color);
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA{
    font-family: 'Hanken Grotesk', sans-serif;
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA ._1Ou1tAFc1VCD4TNa0XwE7z {
    padding: 0%;
}

main.settings ._2oSbenaQCQZPXOD75Sj6nE {
    margin: 0;
}

main.settings .Icon--picture{
    display: none !important;
}

main.settings ._1MKAF96uXJHSW-a1nWoriQ {
    width: 100%;
    margin: 10px 0px 0px 0px;
}

main.settings ._1Fq_tZ_l7SwH_r_a_SNOtA ._10Kns8R3VdHSGFrESIz-4B{
    margin: 10px 0px 0px 0px;
}

main.settings .Icon--trash{
    fill: 'maroon';
}

._6IMSWtd3wtGGn2KgOcZIK{
    background-color: #FFF;
    padding: 10px 0px;
}