#logo-sidebar{
    background: #17171B;
}

ul.sidebar-menu li a{
    padding: .75rem 1rem; 
    border-radius: 1.5rem;
    align-items: center;
    display: flex;
    color: rgb(255 255 255);
    letter-spacing: .5px;
    font-size: 17px;
    font-weight: 400;
}

ul.sidebar-menu li a.active{
    color: #EDAE49;
    font-weight: 500;
}

ul.sidebar-menu li a svg{
    width: 24px;
    height: 24px;
}

ul.sidebar-menu li a span{
    margin-left: 1.25rem;
}

ul.sidebar-menu li a:hover{
    background-color: rgba(255,255,255,0.15);
}